import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '../src/styles/globalStyles';

import light from '../src/styles/themes/light';

export function wrapRootElement({ element }) {
  return(
    <ThemeProvider theme={light}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  )
}
