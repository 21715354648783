// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contato-index-jsx": () => import("./../../../src/pages/contato/index.jsx" /* webpackChunkName: "component---src-pages-contato-index-jsx" */),
  "component---src-pages-contato-sucesso-index-jsx": () => import("./../../../src/pages/contato/sucesso/index.jsx" /* webpackChunkName: "component---src-pages-contato-sucesso-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-produtos-index-jsx": () => import("./../../../src/pages/produtos/index.jsx" /* webpackChunkName: "component---src-pages-produtos-index-jsx" */),
  "component---src-pages-servicos-index-jsx": () => import("./../../../src/pages/servicos/index.jsx" /* webpackChunkName: "component---src-pages-servicos-index-jsx" */),
  "component---src-pages-sobre-index-jsx": () => import("./../../../src/pages/sobre/index.jsx" /* webpackChunkName: "component---src-pages-sobre-index-jsx" */),
  "component---src-templates-product-index-jsx": () => import("./../../../src/templates/product/index.jsx" /* webpackChunkName: "component---src-templates-product-index-jsx" */)
}

